<template>
    <Component
        :is="componentInstance"
        v-bind="$attrs"
        :value="value"
        :get-data-vuex="getUtmTags"
        :placeholder="placeholder"
        :multiple="multiple"
        class="white"
        field="id"
        @[event]="$emit(event, $event)">
    </Component>
</template>

<script>
  import { UsersSelect } from "@core/mixins/select/usersSelect";
  import { capitalizeFirstLetter } from "@core/filters";

  export default {
    name: "UtmTagsSelect",
    mixins: [UsersSelect],

    props: {
      namespaceModule: {
        type: String,
        default: null
      },

      lazy: {
        type: Boolean,
        default: true
      },

      name: {
        type: String,
        required: true
      }
    },

    computed: {
      defaultModuleName () {
        return `utm${ capitalizeFirstLetter(this.name) }Filters`;
      }
    },

    methods: {
      async getUtmTags (label = "", isLoading = false) {
        const capitalizeFirstName = `utm${ capitalizeFirstLetter(this.name) }`;
        await this.getUtmFilters(
          this.namespaceModule ?? this.defaultModuleName, this.list, capitalizeFirstName ?? this.value,
          capitalizeFirstName, capitalizeFirstName, label, isLoading, this.filterOptions
        );
        return this.list;
      }
    }
  };
</script>

<style scoped>

</style>